
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { ClientObject } from '@/store/modules/clients/ClientsModule';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'client-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('clientUpdating'), [
          translate('clients'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_CLIENT,
        route.params.id
      );

      const client = ref<ClientObject>(data.data);

      const titleAr = () => {
        return client.value.title.find((i) => {
          if (i.languageCode === 'ar') {
            return i.title;
          }
        }).title;
      };
      const titleEn = () => {
        return client.value.title.find((i) => {
          if (i.languageCode === 'en') {
            return i.title;
          }
        }).title;
      };
      const titleKu = () => {
        return client.value.title.find((i) => {
          if (i.languageCode === 'ku') {
            return i.title;
          }
        }).title;
      };

      const goBack = () => {
        router.back();
      };

      const clientMobile = computed(() => {
        const mobileString = String(client.value.phoneNumber);
        const last7Digits = mobileString.slice(-10);
        return String(last7Digits);
      });

      const clientSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        phoneNumber: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Phone Number'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        type: Yup.string()
          .required(() => translate('ROLE_IS_REQUIRED_FIELD'))
          .label('Type'),
      });
      const onSubmitCreate = async (values) => {
        values.title = [];
        values.title.push({ languageCode: 'en', title: values.titleEn });
        values.title.push({ languageCode: 'ar', title: values.titleAr });
        values.title.push({ languageCode: 'ku', title: values.titleKu });
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.titleEn;
        delete values.titleAr;
        delete values.titleKu;
        values.isActive = true;

        values.phoneNumber = '964' + values.phoneNumber;

        await store.dispatch(Actions.UPDATE_CLIENT, {
          data: values,
          id: client.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_CLIENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'clients-listing' });
          });
        }
      };

      return {
        onSubmitCreate,
        clientMobile,
        selectedItem,
        submitButton,
        titleAr,
        titleEn,
        titleKu,
        translate,
        clientSchema,
        goBack,
        client,
        can,
      };
    },
  });
